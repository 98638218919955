// const btn = document.getElementById('js-burger-btn');
// const menu = document.getElementById('js-navbar');
//
// btn.addEventListener('click', () => {
// 	menu.classList.toggle('is-navbar-open');
// });

function closeAppBanner() {
    const closeAppBanner = document.getElementById('c-app-banner');
    closeAppBanner.style.display = 'none';
}
window.onload = function () {
    setTimeout(() => {
        const closeAppBanner = document.getElementById('c-app-banner');
        closeAppBanner.style.display = 'flex';
    }, 3000);

};

const items = document.getElementById("c-aside-games-items");
const itemHeight = 110;
const visibleItems = 4;
const totalItems = items.children.length;
let currentIndex = 0;

for (let i = 0; i < visibleItems; i++) {
    const clone = items.children[i].cloneNode(true);
    items.appendChild(clone);
}

function scrollItems() {
    currentIndex++;

    if (currentIndex > totalItems) {
        currentIndex = 1;
        items.style.transition = "none";
        items.style.transform = `translateY(0px)`;
        setTimeout(() => {
            items.style.transition = "transform 0.3s ease";
            items.style.transform = `translateY(-${currentIndex * itemHeight}px)`;
        }, 50);
    } else {
        items.style.transform = `translateY(-${currentIndex * itemHeight
            }px)`;
    }
}
setInterval(scrollItems, 5000);

if (window.innerWidth >= 1024) {

    window.onload = function () {
        var popup = document.getElementById("c-popup");
        var span = document.getElementsByClassName("c-popup-close")[0];
        var documentt = document.getElementsByClassName("overflow-hidden")

        setTimeout(function () {
            popup.style.display = "unset";
            document.body.classList.add('overflow-hidden');
        }, 4000);

        span.onclick = function () {
            popup.style.display = "none";
            document.body.classList.remove('overflow-hidden');
        }

        window.onclick = function (event) {
            if (event.target == popup) {
                popup.style.display = "none";
            }
            if (event.target == documentt) {
                document.body.classList.remove('overflow-hidden');
            }
        }
    }
}